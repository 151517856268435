<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="tricky_questions"
      sort-by="q_id"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Alla kluringar</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Ny Kluring
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <!-- TODO: Validation with vee-validate  -->
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedKluring.q_id"
                        label="Kluringsnummer"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-checkbox
                        v-model="editedKluring.letter_match"
                        label="Bokstavsfråga?"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedKluring.correct_answer"
                        label="Facit"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Avbryt
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Spara
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Är du säker på att du vill radera kluringen?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteKluringConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editKluring(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteKluring(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex"
import {notify} from '../../../utils/notification'

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Kluringsnummer',
        align: 'start',
        sortable: false,
        value: 'q_id',
      },
      { text: 'Bokstavsfråga?', value: 'letter_match' },
      { text: 'Facit', value: 'correct_answer' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    tricky_questions: [],
    editedIndex: -1,
    editedKluring: {
      q_id: 1,
      letter_match: true,
      correct_answer: ''
    },
    defaultStation: {
      q_id: 1,
      letter_match: true,
      correct_answer: ''
    }
  }),

  computed: {
    ...mapGetters(["notification"]),
    formTitle () {
      return this.editedIndex === -1 ? 'Ny Kluring' : 'Redigera Kluring'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions(["getAllTrickyQuestions", "createTrickyQuestion", "updateTrickyQuestion", "deleteTrickyQuestion", "clearNotifications"]),
    async initialize () {
      const tricky_questions = await this.getAllTrickyQuestions()
      // Reset
      this.tricky_questions = []
      // Filter teams
      tricky_questions.forEach((trickyQuestion) => {
        this.tricky_questions.push({
          q_id: trickyQuestion.q_id,
          letter_match: trickyQuestion.letter_match,
          correct_answer: trickyQuestion.correct_answer
        })
      })
    },

    editKluring (trickyQuestion) {
      this.editedIndex = this.tricky_questions.indexOf(trickyQuestion)
      this.editedKluring = Object.assign({}, trickyQuestion)
      this.dialog = true
    },

    deleteKluring (trickyQuestion) {
      this.editedIndex = this.tricky_questions.indexOf(trickyQuestion)
      this.editedKluring = Object.assign({}, trickyQuestion) // ??
      this.dialogDelete = true
    },

    async deleteKluringConfirm () {
      const trickyQuestion = this.tricky_questions[this.editedIndex]
      await this.deleteTrickyQuestion(trickyQuestion.q_id)
      
      // Notification
      const success = notify(this)
      if (success) {
        await this.initialize()
        this.closeDelete()
      }
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedKluring = Object.assign({}, this.defaultStation)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedKluring = Object.assign({}, this.defaultStation)
        this.editedIndex = -1
      })
    },

    async save () {
      if (this.editedIndex > -1) {
        // UPDATE
        await this.updateTrickyQuestion(this.editedKluring)
      } else {
        // NEW
        await this.createTrickyQuestion(this.editedKluring)
      }

      // Notification
      const success = notify(this)
      if (success) {
        await this.initialize()
        this.close()
      }
    },
  }
}
</script>

<style>

</style>